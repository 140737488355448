import React from "react";
import { Box, Typography, Link } from "@mui/material";
import { height } from "@mui/system";

export const Card = (props) => {
	return (
		<Box width={"360px"} display={"flex"} flexDirection={"column"}>
			<Typography variant="h6" fontWeight={600} py={2}>
				{props?.title}
			</Typography>
			<Box
				sx={{
					width: "350px",
					minHeight: "220px",
					height: "auto",
					padding: 2,
					display: "flex",
					flexDirection: "column",
					// justifyContent: "space-between",
					p: 1,
					boxShadow: 3,
					borderColor: "primary",
				}}
			>
				{props &&
					props.data &&
					props.data.length > 0 &&
					props.data.map((key, index) => {
						return (
							<Box
								key={index}
								sx={{
									height: "40px",
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									px: 1,
								}}
							>
								{key?.type === "link" ? (
									<Link href={key?.value}>{key.label}</Link>
								) : (
									<>
										<Typography>{key?.label}</Typography>
										<Typography fontWeight={600}>
											{key?.value ? key?.value : "No Data"}
										</Typography>
									</>
								)}
							</Box>
						);
					})}
			</Box>
		</Box>
	);
};

import React from "react";
import {
	Box,
	Typography,
	Snackbar,
	CircularProgress,
	Divider,
} from "@mui/material";
import APIURLConstants from "../../config/config";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Close } from "@mui/icons-material";
import { Card } from "../../components/Card";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { alpha, styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";

const columns = [
	{ field: "date", headerName: "Date", flex: 1 },
	{ field: "salePrice", headerName: "Sale Price", flex: 1 },
	{ field: "tenure", headerName: "Tenure", flex: 1 },
	{ field: "priceChange", headerName: "Price Change", flex: 1 },
];
const ODD_OPACITY = 0.2;

export const PropertyDetails = () => {
	const theme = useTheme();
	console.log(alpha(theme.palette.primary.main, ODD_OPACITY));
	const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
		[`& .${gridClasses.row}.even`]: {
			backgroundColor: theme.palette.grey[200],
			"&:hover": {
				backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
				"@media (hover: none)": {
					backgroundColor: "transparent",
				},
			},
			"&.Mui-selected": {
				backgroundColor: alpha(
					theme.palette.primary.main,
					ODD_OPACITY + theme.palette.action.selectedOpacity
				),
				"&:hover": {
					backgroundColor: alpha(
						theme.palette.primary.main,
						ODD_OPACITY +
							theme.palette.action.selectedOpacity +
							theme.palette.action.hoverOpacity
					),
					// Reset on touch devices, it doesn't add specificity
					"@media (hover: none)": {
						backgroundColor: alpha(
							theme.palette.primary.main,
							ODD_OPACITY + theme.palette.action.selectedOpacity
						),
					},
				},
			},
		},
	}));
	const [row, setRow] = React.useState([]);
	const { state } = useLocation();

	const {
		location,
		propertyType,
		salesHistory,
		epcDetails,
		estimatedAnnualCosts,
		utilities,
		transaction,
		construction,
	} = state;
	const navigate = useNavigate();
	const [loading, setLoading] = React.useState(true);

	const [error, setError] = React.useState({
		message: "",
		open: false,
	});

	React.useEffect(() => {
		let data = [];
		transaction &&
			transaction.length > 0 &&
			transaction.map((key, index) => {
				data.push({
					id: index,
					date: key?.date?.short,
					salePrice: key?.pricePaid.amount,
					tenure: key?.tenure,
					priceChange: key?.priceChange?.percentage,
				});
			});
		setRow(data);
		setLoading(false);
	}, [transaction]);
	console.log("row", row);
	return (
		<>
			{loading ? (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						height: "500px",
						alignItems: "center",
					}}
				>
					<CircularProgress />
				</Box>
			) : (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 2,
						width: "100%",
						// px: 4,
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							flexWrap: "wrap",
							gap: 5,
							width: "100%",
							justifyContent: "center",
						}}
					>
						<Card data={location} title="Property Location" />
						<Card data={propertyType} title="Property Type" />
						<Card data={salesHistory} title="Sales History" />
						<Card data={epcDetails} title="EPC Details" />
						<Card data={estimatedAnnualCosts} title="Estimated Annual Costs" />
						<Card data={utilities} title="Utilities" />
					</Box>
					<Box
						sx={{
							width: "1167px",
							display: "flex",
							flexDirection: "column",
							// flexWrap: "wrap",
							// justifyContent: "center",
							alignSelf: "center",
							gap: 3,
						}}
					>
						<Box
							display={"flex"}
							flexDirection={"column"}
							justifyContent={"flex-start"}
							textAlign={"left"}
						>
							<Typography variant="h6" fontWeight={600} py={2}>
								{"Sales History"}
							</Typography>
							<Typography>
								{"Showing all recorded transactions for this property."}
							</Typography>
						</Box>

						<StripedDataGrid
							rows={row}
							columns={columns}
							disableRowSelectionOnClick
							hideFooter
						/>
					</Box>
					<Box
						sx={{
							width: "1167px",
							display: "flex",
							flexDirection: "column",
							// flexWrap: "wrap",
							// justifyContent: "center",
							alignSelf: "center",
							gap: 3,
						}}
					>
						<Box
							display={"flex"}
							flexDirection={"column"}
							justifyContent={"flex-start"}
							textAlign={"left"}
						>
							<Typography variant="h6" fontWeight={600} py={2}>
								{"Construction"}
							</Typography>
							<Typography>{"Details of the property construction."}</Typography>
						</Box>
						<Box>
							{construction &&
								construction.length > 0 &&
								construction.map((key, index) => {
									return (
										<>
											<Box
												sx={{
													display: "flex",
													flexDirection: "row",
													textAlign: "start",
													height: "55px",
													borderTop: 1,
													borderBottom: 1,
													alignItems: "center",
													pl: 2,
												}}
											>
												<Typography fontWeight={600}>{key?.label}</Typography>
											</Box>
											<Box
												sx={{
													display: "flex",
													flexDirection: "row",
													textAlign: "start",
													height: "55px",
													// borderTop: 1,
													borderBottom:
														construction.length - 1 === index ? 1 : 0,
													alignItems: "center",
													pl: 2,
												}}
											>
												<Typography sx={{ opacity: 0.5 }}>
													{key?.value ? key?.value : "No Data"}
												</Typography>
											</Box>
										</>
									);
								})}
						</Box>
					</Box>

					{/* <Snackbar
        vertical="top"
        horizontal="right"
        open={error?.open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={error.message}
        action={
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <Close fontSize="small" />
            </IconButton>
        }
    /> */}
				</Box>
			)}
		</>
	);
};

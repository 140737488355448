const url = "https://v1api.theamethyst.co.uk/api/";
// const url = "http://localhost:8080/api/";

// const url = "http://theamethyst.co.uk:8080/api/";

const APIURLConstants = {
	GET_THEME: `${url}theme`,
	GET_PAGE_FIELD: `${url}getField`,
	SET_PAGE_FIELD: `${url}setField`,
	POST_LOGIN: `${url}user`,
	CREATE_TASK: `${url}createTask`,
	UPDATE_TASK: `${url}updateTask`,
	GET_TASK: `${url}getTask`,
	GET_POSTCODE: `${url}SearchProperties`,
	GET_PROPERTY_DETAILS: `${url}GetAllPropertyDetails`,
};

export default APIURLConstants;

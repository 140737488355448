import React from "react";
import { Box, Typography, Link, Snackbar, IconButton } from "@mui/material";
import APIURLConstants from "../../config/config";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Close } from "@mui/icons-material";

export const Property = () => {
	const [value, setValue] = React.useState("");
	const { state } = useLocation();
	const { propertyData } = state;
	const navigate = useNavigate();
	const [loading, setLoading] = React.useState(false);

	const [error, setError] = React.useState({
		message: "",
		open: false,
	});
	const handleClose = () => {
		setError({
			message: "",
			open: false,
		});
	};
	const handleProperty = async (data) => {
		setLoading(true);
		const option = {
			pid: data?.pid,
		};

		await axios
			.post(APIURLConstants.GET_PROPERTY_DETAILS, option)
			.then((response) => {
				setLoading(false);
				if (response?.data?.property) {
					console.log("Response", response?.data?.property);
					const res = response?.data?.property;
					const resEpc = response?.data?.epc;

					const locationArray = [
						{
							label: "Name",
							value: res?.address?.paon,
						},
						{
							label: "Street",
							value: res?.address?.street,
						},
						{
							label: "Town",
							value: res?.address?.town,
						},
						{
							label: "County",
							value: res?.address?.county,
						},
						{
							label: "Postcode",
							value: res?.address?.postcode,
						},
						{
							label: "Local Authority",
							value: res?.address?.district,
						},
					];
					const propertyType = [
						{
							label: "Form",
							value: res?.type,
						},
						{
							label: "Type",
							value: resEpc?.propertyType,
						},
						{
							label: "Year Built",
							value: resEpc?.constructionAgeBand,
						},
						{
							label: "Total Floor Area",
							value: resEpc?.totalFloorArea?.amount,
						},
						{
							label: "Habitable Rooms",
							value: resEpc?.numberHabitableRooms,
						},
						{
							label: "Heated Rooms",
							value: resEpc?.numberHeatedRooms,
						},
					];
					const salesHistory = [
						{
							label: "Last Sale",
							value: res?.transaction[0].date.short,
						},
						{
							label: "Price Paid",
							value: res?.transaction[0].pricePaid.amount,
						},
						{
							label: "Tenure",
							value: res?.transaction[0].tenure,
						},
						{
							label: "Est. Value",
							value: res?.estimatedCurrentValue.amount,
						},
						{
							label: "New Build",
							value: res?.transaction[0].isNewBuild,
						},
						{
							label: "Previous Sales",
							value: res?.transactionCount,
						},
					];
					const epcDetails = [
						{
							label: "EPC Expiry Date",
							value: resEpc?.expiryDate,
						},
						{
							label: "Current Energy Rating",
							value: resEpc?.currentEnergyRating,
						},
						{
							label: "Potential Energy Rating",
							value: resEpc?.potentialEnergyRating,
						},
					];
					const estimatedAnnualCosts = [
						{
							label: "Heating",
							value: resEpc
								? resEpc?.heatingCostPotential?.amount +
								  " - " +
								  resEpc?.lightingCostPotential?.amount
								: undefined,
						},
						{
							label: "Hot Water",
							value: resEpc
								? resEpc?.hotWaterCostPotential?.amount +
								  " - " +
								  resEpc?.hotWaterCostCurrent?.amount
								: undefined,
						},
						{
							label: "Lighting",
							value: resEpc
								? resEpc?.lightingCostPotential?.amount +
								  " - " +
								  resEpc?.lightingCostCurrent?.amount
								: undefined,
						},
						{
							label: "Council Tax",
							type: "link",
							value: "https://www.gov.uk/council-tax-bands",
						},
						{
							label: "Insurance",
							type: "link",
							value: "https://www.gov.uk/council-tax-bands",
						},
					];

					const utilities = [
						{
							label: "Mains Gas",
							value: resEpc?.mainsGasFlag,
						},
						{
							label: "Energy Tariff",
							value: resEpc?.energyTariff,
						},
						{
							label: "Main Fuel",
							value: resEpc?.mainFuel,
						},
						{
							label: "Electricity",
							type: "link",
							value: "https://www.gov.uk/council-tax-bands",
						},
						{
							label: "Broadband",
							type: "link",
							value: "https://www.gov.uk/council-tax-bands",
						},
					];

					const construction = [
						{
							label: "Roof",
							value: resEpc?.roofDescription,
						},
						{
							label: "Walls",
							value: resEpc?.wallsDescription,
						},
						{
							label: "Floor",
							value: resEpc?.floorDescription,
						},
						{
							label: "Windows",
							value: resEpc?.windowsDescription,
						},
						{
							label: "Lighting",
							value: resEpc?.lightingDescription,
						},
						{
							label: "Heating",
							value: resEpc?.heatDescription,
						},
						{
							label: "Hot Water",
							value: resEpc?.hotwaterDescription,
						},
					];
					navigate("/customer/property/details", {
						state: {
							location: locationArray,
							propertyType: propertyType,
							salesHistory: salesHistory,
							epcDetails: epcDetails,
							estimatedAnnualCosts: estimatedAnnualCosts,
							utilities: utilities,
							transaction: res?.transaction,
							construction: construction,
						},
					});
					return;
				}
				setError({ message: response?.data?.message, open: true });
			})
			.catch((error) => {
				setLoading(false);

				console.log(error);
				return error;
			});
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				gap: 2,
				// width: "100%",
				// px: 10,
				// justifyContent: "flex-start",
				justifyContent: "center",
				alignItems: "center",
				alignContent: "center",
			}}
		>
			<Box
				sx={{
					disply: "flex",
					flexDirection: "column",
				}}
			>
				<Box sx={{ display: "flex", py: 2 }}>
					<Typography variant="h5">
						{"Select a property to continue."}
					</Typography>
				</Box>
				{propertyData && propertyData.property.length > 0 ? (
					propertyData.property.map((key, index) => {
						return (
							<Box
								sx={{ height: "50px", display: "flex", cursor: "pointer" }}
								key={index}
								onClick={() => handleProperty(key)}
							>
								<Link>{key.address.description}</Link>
							</Box>
						);
					})
				) : (
					<Typography>{"Data not present"}</Typography>
				)}
			</Box>
			<Snackbar
				vertical="top"
				horizontal="right"
				open={error?.open}
				autoHideDuration={6000}
				onClose={handleClose}
				message={error.message}
				action={
					<IconButton
						size="small"
						aria-label="close"
						color="inherit"
						onClick={handleClose}
					>
						<Close fontSize="small" />
					</IconButton>
				}
			/>
		</Box>
	);
};

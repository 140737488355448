import React from "react";
import logo from "./logo.svg";
import "./App.css";
// import SearchAppBar from "./header";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Box, Divider, CircularProgress } from "@mui/material";
import SearchAppBar from "./pages/Header";
import { Footer } from "./pages/Footer";
import { Accessibility } from "accessibility";
import { InnerRouter } from "./router/InnerRouter";
import { Home } from "./pages/Home";
import { themeService } from "./service/ThemeService";
import axios from "axios";
import APIURLConstants from "./config/config";

function App() {
	// const client = window.location.href.includes("vendor1");
	// console.log("vendor1", client);

	const [themeData, setThemeData] = React.useState("");
	const getTheme = async () => {
		var themeOptions = {
			method: "get",
			url: APIURLConstants.GET_THEME,
			headers: {
				"Access-Control-Allow-Origin": "http://localhost:3000",
			},
			json: true,
		};
		await axios(themeOptions)
			.then((response) => {
				console.log("respose if", response);
				setThemeData(response.data[0]);
			})
			.catch((error) => {
				console.log("eror ", error);
				return error;
			});
	};
	React.useEffect(() => {
		try {
			getTheme();
		} catch (error) {
			console.log("error", error);
		}
		// setThemeData(data);
		// console.log("data-----", data);
	}, []);

	// var instance = new Accessibility();

	const getApptheme = () => {
		return createTheme({
			palette: {
				primary: {
					main: themeData !== "" ? themeData.primarycolor : "#FF0000",
				},
				secondary: {
					main: themeData !== "" ? themeData.secondarccolor : "#D9D9D9",
				},
			},
		});
	};

	return (
		<div className="App">
			{themeData ? (
				<ThemeProvider theme={getApptheme()}>
					<Box width={"1390px"}>
						<SearchAppBar />
						<Box height={"100%"} minHeight={"450px"}>
							<InnerRouter />
						</Box>
						<Divider orientation="horizontal" flexItem />
						<Footer />
					</Box>
				</ThemeProvider>
			) : (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						height: "500px",
						alignItems: "center",
					}}
				>
					<CircularProgress />
				</Box>
			)}
		</div>
	);
}

export default App;

import React from "react";
import {
	Box,
	Typography,
	TextField,
	Button,
	Snackbar,
	IconButton,
	CircularProgress,
} from "@mui/material";
import APIURLConstants from "../../config/config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Close } from "@mui/icons-material";

export const Postcode = () => {
	const naviagate = useNavigate();
	const [value, setValue] = React.useState("");
	const [loading, setLoading] = React.useState(false);

	const [error, setError] = React.useState({
		message: "",
		open: false,
	});
	const handleClose = () => {
		setError({
			message: "",
			open: false,
		});
	};
	const handleSearch = async () => {
		setLoading(true);
		const option = {
			postcode: value,
		};

		await axios
			.post(APIURLConstants.GET_POSTCODE, option)
			.then((response) => {
				setLoading(false);
				if (response?.data?.property) {
					naviagate("/customer/property", {
						state: { propertyData: response.data },
					});
				}
				setError({ message: response?.data?.message, open: true });
			})
			.catch((error) => {
				setLoading(false);

				console.log(error);
				return error;
			});
	};
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				gap: 4,
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					// justifyContent: "center",
					border: 1,
					borderRadius: "32px",
					background: "#D9D9D9",
					width: "600px",
					height: "60px",
					px: 4,
					alignItems: "center",
				}}
			>
				<Typography fontSize={"47"}>{"What is the postcode?"}</Typography>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					// justifyContent: "center",
					px: 4,
					border: 1,
					borderRadius: "32px",
					width: "600px",
					height: "60px",
					alignItems: "center",
					background: "#D9D9D9",
					gap: 2,
				}}
			>
				{/* <InputComponent
					label={"postcode"}
					placholder="postcode"
					value={props.data[0].url}
					handleInput={handleInput}
				/> */}
				<TextField
					id="outlined-basic"
					variant="outlined"
					label={"PostCode"}
					placeholder={"Post Code"}
					size="small"
					sx={{
						borderRadius: "8px",
						my: 1,
						width: "500px",
						background: "#fff",
					}}
					value={value}
					onChange={(e) => {
						setValue(e.target.value);
					}}
				/>
				<Button
					variant="contained"
					onClick={handleSearch}
					sx={{ width: 130, height: "35px" }}
				>
					{loading ? <CircularProgress color="#fff" size="20px" /> : "Search"}
				</Button>
			</Box>
			<Snackbar
				vertical="top"
				horizontal="right"
				open={error?.open}
				autoHideDuration={6000}
				onClose={handleClose}
				message={error.message}
				action={
					<IconButton
						size="small"
						aria-label="close"
						color="inherit"
						onClick={handleClose}
					>
						<Close fontSize="small" />
					</IconButton>
				}
			/>
		</Box>
	);
};

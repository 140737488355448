import React from "react";
import {
	Box,
	Typography,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export const Landing = () => {
	const naviagte = useNavigate();
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				gap: 4,
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Typography color="initial" fontSize={"47"}>
				{"Great! Lets see if you can get a mortgage on this."}
			</Typography>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					// justifyContent: "center",
					border: 1,
					borderRadius: "32px",
					background: "#D9D9D9",
					width: "600px",
					height: "60px",
					pl: 4,
					alignItems: "center",
				}}
			>
				<Typography fontSize={"47"}>
					{"Have you found your dream home?"}
				</Typography>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					// justifyContent: "center",
					pl: 4,
					border: 1,
					borderRadius: "32px",
					width: "600px",
					alignItems: "center",
					background: "#D9D9D9",
				}}
			>
				<FormControl>
					<RadioGroup
						aria-labelledby="demo-radio-buttons-group-label"
						// defaultValue="female"
						name="radio-buttons-group"
					>
						<FormControlLabel
							value="yes"
							control={
								<Radio
									size="small"
									onClick={() => naviagte("/customer/postcode")}
								/>
							}
							label="Yes"
						/>
						<FormControlLabel
							value="no"
							control={<Radio size="small" />}
							label="No"
						/>
					</RadioGroup>
				</FormControl>
			</Box>
		</Box>
	);
};

import React from "react";
import {
	Routes,
	Route,
	Navigate,
	RouterProvider,
	createBrowserRouter,
} from "react-router-dom";
import RouteConstants from "../constants/RouteConstant";
import { Acknoweldge } from "../pages/Acknoweldge";
import { Landing } from "../pages/Client/Landing";
import { Postcode } from "../pages/Client/Postcode";
import { Home } from "../pages/Home";
import { Login } from "../pages/Login";
import { Task } from "../pages/Task";
import { TaskStatus } from "../pages/TaskStatus";
import { VendorRegistration } from "../pages/VendorRegistration";
import { Property } from "../pages/Client/Property";
import { PropertyDetails } from "../pages/Client/PropertyDetails";

export const InnerRouter = () => {
	const router = createBrowserRouter([
		{
			path: "/",
			element: <Login />,
		},
		{
			path: "/fca",
			element: <Home />,
		},
		{
			path: "regsiter",
			element: <VendorRegistration />,
		},
		{
			path: "/fca/:id",
			element: <Home />,
		},
		{
			path: "regsiter/:id",
			element: <VendorRegistration />,
			// loader: rootLoader,
		},
		{
			path: "acknoweldge",
			element: <Acknoweldge />,
			// loader: rootLoader,
		},
		{
			path: "login",
			element: <Login />,
			// loader: rootLoader,
		},
		{
			path: "task",
			element: <Task />,
			// loader: rootLoader,
		},
		{
			path: "taskStatus",
			element: <TaskStatus />,
			// loader: rootLoader,
		},
		{
			path: "customer",
			element: <Landing />,
			// loader: rootLoader,
		},
		{
			path: "customer/postcode",
			element: <Postcode />,
			// loader: rootLoader,
		},
		{
			path: "customer/property",
			element: <Property />,
			// loader: rootLoader,
		},
		{
			path: "customer/property/details",
			element: <PropertyDetails />,
			// loader: rootLoader,
		},
	]);

	return (
		<>
			<RouterProvider router={router} />
		</>
	);
};

import React from "react";
import { Box } from "@mui/material";
import { TextField, Button, Typography, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import axios from "axios";
import APIURLConstants from "../config/config";
export const Login = () => {
	let navigate = useNavigate();
	const [emailErr, setEmailErr] = React.useState("");
	const [passwordErr, setPasswordErr] = React.useState("");

	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);

		if (data.get("email") === "") {
			setEmailErr("Email required");
			return;
		} else {
			const email = data.get("email").trim();
			const flag = email !== "" ? validateEmail(email) : true;
			if (!flag) {
				const emlErr = flag ? "" : "Please enter valid Email";
				setEmailErr(emlErr);
				return;
			}
		}

		if (data.get("password") === "") {
			setPasswordErr("Password required");
			return;
		}
		let obj = {};
		try {
			const url =
				APIURLConstants.POST_LOGIN +
				"/" +
				data.get("email").trim() +
				"/" +
				data.get("password");
			console.log(url);
			axios
				.get(url)
				.then((res) => {
					if (res.data) {
						console.log("res", res);
						if (res.data) {
							console.log("data", res.data.role);
							localStorage.setItem("role", res.data.role);
							localStorage.setItem("userId", res.data.id);

							if (res.data.role === "admin") {
								// navigate("regsiter/vendor");
								navigate("/taskStatus");
							}
							if (res.data.role === "manager") {
								navigate("/task");
								// navigate("/regsiter/vendor");
							}
						} else {
							setPasswordErr("something wrong");
							setEmailErr("something wrong");

							// if (res.data.password) {
							// 	setPasswordErr(res.data.message);
							// 	return;
							// } else {
							// 	setEmailErr(res.data.message);
							// 	return;
							// }
						}
					} else {
						// disableButton.set(false);
						// setSnackBarProps({
						// 	...snackBarProp,
						// 	openSnack: true,
						// 	variant: "error",
						// 	message: "This event name already exists",
						// });
					}
					// loading.set(false);
				})
				.catch((error) => {
					console.log("login Api", error);
				});
		} catch (error) {
			console.log("login Api", error);
		}
	};

	const validateEmail = (value) => {
		value = value.trim();
		if (validator.isEmail(value)) {
			return true;
		} else return false;
	};

	return (
		<>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexWrap: "wrap",

					flexDirection: { xs: "column", md: "row" },
					p: { xs: 0, md: 2 },
				}}
			>
				<Box
					sx={{
						width: "100%",
						// height: "100vh",
						// height: "calc(100vh - 70px)",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						alignContent: "center",
					}}
				>
					<Box
						sx={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: { xs: "center", md: "center" },
						}}
					>
						<Typography
							component="h1"
							variant="h5"
							alignContent={"flex-start"}
							fontWeight={600}
						>
							Login
						</Typography>
						<Box
							component="form"
							noValidate
							onSubmit={handleSubmit}
							sx={{ mt: 3, width: { xs: 290, md: 400 } }}
						>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextField
										required
										fullWidth
										id="email"
										label="Email Address"
										name="email"
										autoComplete="email"
										color="secondary"
										error={emailErr.length > 0 ? true : false}
										helperText={emailErr.length > 0 ? emailErr : ""}
									/>
								</Grid>

								<Grid item xs={12}>
									<TextField
										required
										fullWidth
										name="password"
										label="Password"
										type="password"
										id="password"
										autoComplete="new-password"
										color="secondary"
										error={passwordErr.length > 0 ? true : false}
										helperText={passwordErr.length > 0 ? passwordErr : ""}
									/>
								</Grid>
							</Grid>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								sx={{
									mt: 3,
									mb: 2,
								}}
							>
								Login
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	);
};
